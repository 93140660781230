
<template>
  <div class="tokyo_tm_about">
    <Subscribe />
  </div>
</template>

<script>
  import Subscribe from "../components/Subscribe"

  export default {
    components: {
      Subscribe
    }
  };
</script>

<style lang="scss"></style>
